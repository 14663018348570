import React from "react";

import styles from "./PageNotFound.module.scss";

export default function PageNotFound() {
  return (
    <div className={styles["container"]}>
      <div className={styles["body"]}>
        <h1>404 Page Not Found</h1>
      </div>
    </div>
  );
}
